<template>
    <div id="recaptcha-container">
        <div id="grecaptcha"></div>
        <div class="reset-container" v-if="resetOption">
            <button @click="resetRecaptcha()">¿reCAPTCHA no funciona? Reiniciar</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        scale: {
            type: Number,
            default: 1,
            required: false
        },
        resetOption: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    mounted() {
        this.renderRecaptcha()
        this.setStyles()
    },
    methods: {
        renderRecaptcha() {
            grecaptcha.render('grecaptcha', {
                'sitekey': this.$config['RECAPTCHA_SITE_KEY'],
                'callback': this.onVerify,
                'theme': 'light',
                'size': 'normal'
            })
        },
        onVerify(token) {
            this.$emit('verifyRecaptcha', token)
        },
        setStyles() {
            let container = document.querySelector('#recaptcha-container')
            container.style.transform = `scale(${this.scale})`
            container.style.transformOrigin = '0 0'
            container.style.webkitTransform = `scale(${this.scale})`
            container.style.webkitTransformOrigin = '0 0'
        },
        resetRecaptcha() {
            grecaptcha.reset()
        }
    }
}
</script>

<style scoped>
#recaptcha-container .reset-container {
    display: flex;
    justify-content: center;
}

#recaptcha-container .reset-container button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    margin-top: 5px;
    outline: none;
    padding: 0;
    text-decoration: underline;
}
</style>